import * as React from "react";
import { PropertyFaq as SharedUiPropertyFaq } from "@shared-ui/lodging-property-details";
import { PropertyBexApiWrapper } from "components/shared/BexApiWrapper/PropertyBexApiWrapper";
import { UitkSpacing } from "@egds/react-core/spacing";
import { PropertyFaqProps } from "./types";

const WrappedPropertyFaq = PropertyBexApiWrapper(SharedUiPropertyFaq);

export const PropertyFaq = (props: PropertyFaqProps) => {
  const { context, templateComponent } = props;
  const { displayThresHold, enableExpando, showBorder } = templateComponent.config;
  return (
    <UitkSpacing
      margin={
        showBorder
          ? {
              small: { blockend: "two", inline: "three" },
              medium: { blockend: "two", inline: "unset" },
            }
          : { block: "three" }
      }
    >
      <section>
        <WrappedPropertyFaq
          context={context}
          displayThreshold={displayThresHold}
          showExpandoPeek={enableExpando}
          showBorder={showBorder}
        />
      </section>
    </UitkSpacing>
  );
};

export default PropertyFaq;
